@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;1,400;1,500&display=swap');

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$article-font-family: 'Lora', serif;
$font-colour-light: white;
$font-colour-dark: #3C3C3C;
$background-fill-colour: #f6f4f3;

html {
    font-family: $base-font-family;
}

a {
    color: #3075b1;

    &:visited {
        color: #915670;
    }
}

body {
    margin: 0;
    background: center top / contain no-repeat url('/assets/background-top.svg'),
    center bottom / contain no-repeat url('/assets/background-bottom.svg') #f6f4f3;
    padding: 0 1ch;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > header {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        color: $font-colour-light;
        margin-bottom: 1rem;

        > * {
            flex-basis: 33%;
        }

        h1 {
            font-size: x-large;
            margin: 0;
            padding: 1rem 0;
            font-weight: normal;
            letter-spacing: 0.3ch;
        }

        .logo-link {
            text-align: center;
        }

        nav {
            display: flex;
            flex-grow: 1;
            background: white;
            box-sizing: border-box;
            padding: 0.5em;
            border-radius: 0.5em;
            color: $font-colour-dark;

            a {
                padding: 0 2ch;
                margin-left: -1px;
                flex-grow: 1;
                color: inherit;
                text-align: center;
            }
        }

        a {
            text-decoration: none;
            color: inherit;

            &:visited {
                color: inherit;
            }
        }
    }
}

#logo {
    height: 2.5rem;
}

article {
    font-family: $article-font-family;
    font-size: 1.3rem;
    width: 80ch;
    max-width: 100%;
    margin: 0 auto;
    background: linear-gradient(white 5rem, $background-fill-colour 18rem) $background-fill-colour;
    box-sizing: border-box;
    padding: 2em 1em;
    border-radius: 10px;
    position: relative;

    &:not(.extract)::before {
        content: "";
        width: 100%;
        position: absolute;
        height: 17rem;
        border-radius: 10px 10px 0 0;
        box-shadow: 0 1px 5px 1px rgba(0,0,0,0.1);
        max-height: 100%;
        z-index: -1;
        left: 0;
        top: 0;
    }

    h1 {
        font-size: 3rem;
        font-weight: normal;
        color: #333;
        text-align: center;

        &.post-title {
            margin-top: 2.5em;

            &::after {
                content: "";
                width: 75%;
                display: block;
                border-bottom: 1px solid #b0b0b0;
                margin: 5rem auto 0;
            }
        }

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    p {
        line-height: 1.7em;
        margin: 0 0 2em;
        color: $font-colour-dark;
        text-align: justify;
    }

    img {
      max-width: 100%;
      border: 2ch solid $font-colour-light;
      border-radius: 10px;
      box-shadow: 0 3px 2px rgba(0,0,0,0.2);
      box-sizing: border-box;
    }
}

.extract {
    background: #ffffff;
    box-shadow: 0 2px 2px 0.4px rgba(0,0,0,0.2);
    overflow: hidden;
    margin-bottom: 5em;

    .post-meta {
    }

    .tag-list {
        margin: 0;
        padding: 0;

        li {
            display: inline;

            &:not(:last-child)::after {
                content: ",";
            }
        }
    }

    .read-post {
        position: absolute;
        right: -10rem;
        bottom: -10rem;
        background-image: url(/assets/background-top.svg);
        background-color: #ba878a;
        color: white;
        text-decoration: none;
        font-weight: bold;
        box-sizing: border-box;
        padding: 2rem 11rem 11rem 4rem;
        border-radius: 50%;
        box-shadow: inset 0 1px 3px 2px rgba(0,0,0,0.4);
        background-size: auto 7rem;

        &:visited {
            color: white;
        }
    }
}

.post-meta {
    font-size: 1rem;
    margin-top: 5rem;

    dt, dd {
        display: inline;
    }

    dt {
        color: #cfced5;

        &::after {
            content: ":";
        }
    }

    dd {
        margin-left: 0;
    }
}

main {
    flex-grow: 1;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.rss-subscribe {
    text-align: center;
}

footer {
    display: flex;
    justify-content: space-around;
    margin-top: 5rem;
    flex-wrap: wrap;

    h1 {
        font-weight: normal;
        font-size: large;
        margin-top: 0;
    }

    > section {
        flex-basis: 25%;
        flex-grow: 1;
        margin: 1em;
    }

    > small {
        flex-basis: 100%;
        text-align: center;
        margin: 1em;
    }
}

#contact-list {
    dt {
        color: #3c3c3c;
        margin: 1em 0 0.2em;
    }

    dd {
        margin: 0;
    }
}

.social-media-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        margin-bottom: 0.2em;
    }
}

.svg-icon {
    width: 1em;
    height: 1em;
}

@media (max-width: 55rem) {
    article {
        font-size: 1rem;
    }
}

@import "syntax-highlighting";
